import React from "react"

import styles from "./target-landings.module.scss"
import TargetLandingsCard from "./target-landings-card"

const TargetLandingsComponent = ({ title, childCare, languageSchool, sportSchool, beautySchool, id }) => (
  <div className={styles.target_landings}>
    <div id={id} className={`container ${styles.target_audience}`}>
      <h2 className={styles.title}>{title || "Параплан - российское решение для направлений"}</h2>
      <div className={styles.itemList}>
        <TargetLandingsCard
          link="/landing/education/"
          title={childCare || "Обучение"}
          image={require("../../../../images/target-audience/education.png")}
          backgroundColor={"#F2A819"} />
        <TargetLandingsCard
          link="/landing/sports/"
          title={languageSchool || "Спорт"}
          image={require("../../../../images/target-audience/sports.png")}
          backgroundColor={"#03C4A4"} />
        <TargetLandingsCard
          link="/landing/creativeschool/"
          title={sportSchool || "Танцы"}
          image={require("../../../../images/target-audience/dancing.png")}
          backgroundColor={"#319DED"} />
        <TargetLandingsCard
          link="/landing/crm-dlya-muzykalnoj-shkoly/"
          title={sportSchool || "Музыка"}
          image={require("../../../../images/target-audience/music.png")}
          backgroundColor={"#6D20D2"} />
        <TargetLandingsCard
          link="/landing/crm-dlja-tvorcheskih-shkol/"
          title={sportSchool || "Творчество"}
          image={require("../../../../images/target-audience/creation.png")}
          backgroundColor={"#319DED"} />
        <TargetLandingsCard
          link="/landing/language/"
          title={sportSchool || "Язык"}
          image={require("../../../../images/target-audience/language.png")}
          backgroundColor={"#F2A819"} />
        <TargetLandingsCard
          link="/landing/crm-dlya-repetitora/"
          title={sportSchool || "Репетиторство"}
          image={require("../../../../images/target-audience/repetitor.png")}
          backgroundColor={"#03C4A4"} />
        <TargetLandingsCard
          link="/landing/beauty/"
          title={beautySchool || "Beauty бизнес"}
          image={require("../../../../images/target-audience/beauty.png")}
          backgroundColor={"#E75D49"} />
      </div>
    </div>
  </div>
)

export default TargetLandingsComponent
